import {
  useAccountPlans,
  useErrorPage,
  useQuery,
  useRouterStore,
  usesRemainingSingleton,
} from "@helium10/re-core";
import { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { checkExtension } from "./checkExtension";
import { ErrorPage } from "./components/ErrorPage";
import { BaseRoutes } from "./Routes";

export function Root() {
  const { error } = useErrorPage();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountId } = useQuery<{ accountId: string }>();
  useAccountPlans({ accountId });
  usesRemainingSingleton.useSingleton();

  useEffect(() => {
    checkExtension(accountId);
  }, [accountId]);

  useEffect(() => {
    window.customNavigate = navigate;
    useRouterStore.getState().setRouter({ navigate, location });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);

  // useEffect(() => {
  //   if (process.env.NODE_ENV === "production") {
  //     initDataDogRum();
  //   }
  // }, []);

  return <Suspense fallback={null}>{!error ? <BaseRoutes /> : <ErrorPage />}</Suspense>;
}
