import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { setTagManager, useGTMStore } from "./store";

export const useGTM = () => {
  const status = useGTMStore((state) => state.GTMInfoRequest.atom.status);
  const location = window.location;

  useEffect(() => {
    useGTMStore.getState().GTMInfoRequest.action();
  }, []);

  useEffect(() => {
    location && status === "loaded" && setTagManager(location);
  }, [location, status]);

  return <></>;
};

export const GTM = memo(() => {
  useGTM();
  return null;
});
