import { baseFetch, getUrlWithSearchParams, Helium10Plans, membersLink } from "@helium10/re-core";

export const getGTMInfo = async () => {
  return await baseFetch<void, { results: IGTMResponse }>({
    url: getUrlWithSearchParams(`${membersLink}/api/v1/customers/segment-traits`, {}),
    method: "GET",
    withCredentials: true,
  });
};

export interface IGTMResponse {
  showOnboarding: boolean;
  email: string;
  emailAddress: string;
  joinDate: string;
  createdAt: string;
  full_name: string;
  firstName: string;
  name: string;
  id: number;
  chromeExtensionLastSeenAt: number;
  lastLogin: string;
  merchant_ids: Array<{
    seller_string_id: string;
    region: string;
  }>;
  plan: Helium10Plans;
  subscriptionLevel: Helium10Plans;
  ppc_enabled: boolean;
  ppc_enabled_raw: boolean;
  ppc_nds_signed: boolean;
  has_ppc_token: boolean;
  subscription: {
    coupon: string;
    plan: Helium10Plans;
  };
  userId: string;
  tester: boolean;
}
