import {
  createSlice,
  getUrlWithSearchParams,
  ICreateRequest,
  useRouterStore,
} from "@helium10/re-core";
import TagManager from "react-gtm-module";
import { create } from "zustand";

import { getGTMInfo, IGTMResponse } from "./IGTMResponse";

interface IGTMLStore {
  GTMInfoRequest: ICreateRequest<void, IGTMResponse | null>;
}

export const useGTMStore = create<IGTMLStore>((set, get) => ({
  ...createSlice(
    set,
    get,
    "GTMInfoRequest",
    async (_: void) => {
      const data = await getGTMInfo();
      const results = data?.results;

      if (results) {
        const { firstName, email, createdAt, plan, ...rest } = results;
        return {
          ...rest,
          name: firstName,
          emailAddress: email,
          joinDate: createdAt,
          subscriptionLevel: plan,
          userId: String(rest.userId),
          lastLogin: String(rest.lastLogin),
          chromeExtensionLastSeenAt: Number(rest.chromeExtensionLastSeenAt),
        } as IGTMResponse;
      } else {
        return null;
      }
    },
    {
      fulfilledReaction: (result) => {
        if (result) {
          TagManager.initialize({
            gtmId: "GTM-5QPG5B",
            dataLayer: result,
          });
          const { location, navigate } = useRouterStore.getState();
          const userId = result.userId;
          if (location) {
            TagManager.dataLayer({
              dataLayer: {
                event: "Page View",
                userId,
                "content-name": location.pathname + location.search,
              },
            });
          }

          if (navigate) {
            if (result?.showOnboarding && window.location.pathname !== "/onboarding") {
              navigate(getUrlWithSearchParams("/onboarding", {}), { replace: true });
            }
          }
        }
      },
    },
  ),
}));

export const setTagManager = (location: Location) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "Page View",
      userId: useGTMStore.getState().GTMInfoRequest.atom.content?.userId,
      "content-name": location.pathname + location.search,
    },
  });
};
