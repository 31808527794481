import { useCredentials, useQuery } from "@helium10/re-core";
import { lazy, memo, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Audience = lazy(() =>
  import(/* webpackChunkName: "Audience" */ "@helium10/re-audience").then((module) => ({
    default: module.Audience,
  })),
);

const NewMenu = lazy(() =>
  import(/* webpackChunkName: "NewMenu" */ "@helium10/re-features").then((module) => ({
    default: module.NewMenu,
  })),
);

const NewModalSellingLevel = lazy(() =>
  import(/* webpackChunkName: "NewModalSellingLevel" */ "@helium10/re-features").then((module) => ({
    default: module.NewModalSellingLevel,
  })),
);

const ListingBuilder = lazy(() =>
  import(/* webpackChunkName: "ListingBuilder" */ "@helium10/re-listing-builder").then(
    (module) => ({
      default: module.ListingBuilder,
    }),
  ),
);

const ListingAnalyzer = lazy(() =>
  import(/* webpackChunkName: "ListingAnalyzer" */ "@helium10/re-listing-analyzer").then(
    (module) => ({
      default: module.ListingAnalyzer,
    }),
  ),
);

const CerebroRoutes = lazy(() =>
  import(/* webpackChunkName: "CerebroRoutes" */ "@helium10/re-research-tools").then((module) => ({
    default: module.CerebroRoutes,
  })),
);
const MagnetRoutes = lazy(() =>
  import(/* webpackChunkName: "MagnetRoutes" */ "@helium10/re-research-tools").then((module) => ({
    default: module.MagnetRoutes,
  })),
);
const BlackBoxRoutes = lazy(() =>
  import(/* webpackChunkName: "BlackBoxRoutes" */ "@helium10/re-research-tools").then((module) => ({
    default: module.BlackBoxRoutes,
  })),
);
const EliteAnalyticsRoutes = lazy(() =>
  import(/* webpackChunkName: "EliteAnalyticsRoutes" */ "@helium10/re-research-tools").then(
    (module) => ({
      default: module.EliteAnalyticsRoutes,
    }),
  ),
);
const KeywordTrackerRoutes = lazy(() =>
  import(/* webpackChunkName: "KeywordTrackerRoutes" */ "@helium10/re-research-tools").then(
    (module) => ({
      default: module.KeywordTrackerRoutes,
    }),
  ),
);

const Onboarding = lazy(() =>
  import(/* webpackChunkName: "Onboarding" */ "@helium10/re-miscellaneous").then((module) => ({
    default: module.Onboarding,
  })),
);

const Profits = lazy(() =>
  import(/* webpackChunkName: "Profits" */ "@helium10/re-profits").then((module) => ({
    default: module.Profits,
  })),
);

const InventoryManagement = lazy(() =>
  import(/* webpackChunkName: "InventoryManagement" */ "@helium10/re-inventory-management").then(
    (module) => ({
      default: module.InventoryManagement,
    }),
  ),
);

// const MarketTracker = lazy(() =>
//   import(/* webpackChunkName: "MarketTracker" */ "@helium10/re-dsa").then((module) => ({
//     default: module.MarketTracker,
//   })),
// );
const MarketTracker = lazy(() =>
  import(/* webpackChunkName: "MarketTracker" */ "@helium10/re-dsa").then((module) => ({
    default: module.MarketTracker,
  })),
);

const BusinessValuation = lazy(() =>
  import(/* webpackChunkName: "BusinessValuation" */ "@helium10/re-miscellaneous").then(
    (module) => ({
      default: module.BusinessValuation,
    }),
  ),
);

const ConnectionFlow = lazy(() =>
  import(/* webpackChunkName: "ConnectionFlow" */ "@helium10/re-miscellaneous").then((module) => ({
    default: module.ConnectionFlow,
  })),
);

const TokensManager = lazy(() =>
  import(/* webpackChunkName: "TokensManager" */ "@helium10/re-miscellaneous").then((module) => ({
    default: module.TokensManager,
  })),
);

// const Pim = lazy(() =>
//   import(/* webpackChunkName: "TokensManager" */ "@helium10/re-pim").then((module) => ({
//     default: module.Pim,
//   })),
// );

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "@helium10/re-control-center").then((module) => ({
    default: module.Dashboard,
  })),
);

const Modals = lazy(() =>
  import(/* webpackChunkName: "Modals" */ "@helium10/re-features").then((module) => ({
    default: module.ModalsPage,
  })),
);

const ManagedRefundService = lazy(() =>
  import(/* webpackChunkName: "ManagedRefundService" */ "@helium10/re-miscellaneous").then(
    (module) => ({
      default: module.ManagedRefundService,
    }),
  ),
);

export const BaseRoutes = memo(() => {
  const { accountId } = useQuery<{ accountId: string }>();
  const token = useCredentials(accountId);

  return (
    <Routes>
      <Route path={`/onboarding/*`} element={<Onboarding />} />
      <Route path={`/new-account/tokens-manager/add/*`} element={<ConnectionFlow />} />
      <Route
        path={"/new-account/tokens-manager/success/*"}
        element={<ConnectionFlow step={"googleConnectionConfirmStep"} />}
      />
      <Route
        path={"/new-account/tokens-manager/fail/*"}
        element={<ConnectionFlow step={"googleConnectionErrorStep"} />}
      />
      <Route
        path="/*"
        element={
          <>
            <NewMenu accountId={accountId ? Number(accountId) : undefined} logoutToken={token}>
              <Suspense fallback={null}>
                <Routes>
                  <Route path={`/managed-refund-service/*`} element={<ManagedRefundService />} />
                  <Route path={`/audience/*`} element={<Audience />} />
                  <Route path={`/cerebro-asin/*`} element={<Navigate to={"cerebro"} replace />} />
                  <Route path={`/cerebro/*`} element={<CerebroRoutes />} />
                  <Route path={`/magnet/*`} element={<MagnetRoutes />} />
                  <Route path={`/black-box/*`} element={<BlackBoxRoutes />} />
                  <Route path={`/elite-analytics/*`} element={<EliteAnalyticsRoutes />} />
                  <Route path={"/listing-analyzer/*"} element={<ListingAnalyzer />} />
                  <Route path={"/listing-builder/*"} element={<ListingBuilder />} />
                  <Route path={"/profits/*"} element={<Profits />} />
                  <Route path={"/inventory-management/*"} element={<InventoryManagement />} />
                  <Route path={"/business-valuation/*"} element={<BusinessValuation />} />
                  <Route path={"/new-account/tokens-manager/*"} element={<TokensManager />} />
                  <Route
                    path={"/new-account/tokens-manager-list/*"}
                    element={<TokensManager newConnection />}
                  />
                  <Route path="/mt360/*" element={accountId && token ? <MarketTracker /> : null} />
                  <Route
                    path="/mt360-beta/*"
                    element={accountId && token ? <MarketTracker /> : null}
                  />
                  <Route path={"/dashboard/*"} element={<Dashboard />} />
                  <Route path={`/modals/*`} element={<Modals />} />
                  <Route path={`/keyword-tracker-beta/*`} element={<KeywordTrackerRoutes />} />
                </Routes>
              </Suspense>
            </NewMenu>
            <NewModalSellingLevel />
          </>
        }
      ></Route>
    </Routes>
  );
});
